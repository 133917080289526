import { LIST_CATEGORIES_FAILURE, LIST_CATEGORIES_SUCCESS } from './actionTypes';
import { listCategoriesAPI } from '../api/fatwaAPI';

function listCategoriesSuccess(data) {
    return { data, type: LIST_CATEGORIES_SUCCESS };
}

function listCategoriesFailure(response) {
    return { response, type: LIST_CATEGORIES_FAILURE };
}


export function listCategories() {
    return dispatch => listCategoriesAPI()
        .then(response => dispatch(listCategoriesSuccess(response.data)))
        .catch(response => dispatch(listCategoriesFailure(response)));
}
