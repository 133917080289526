import { isEmpty } from '../utils/lodashUtils';

import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    TOKEN_EXPIRED_TRUE,
    LOGOUT,
    LOGIN_SUCCESS_RESET,
    TOKEN_EXPIRED_FALSE,
} from '../actions/actionTypes';
import { getUser } from '../utils/utils';


const user = getUser();
const initialState = {
    loginSuccess: null,
    userToken: !isEmpty(user) ? user.token : null,
    user: !isEmpty(user) ? user : null,
    error: null,
    tokenExpired: false,
};

export default function authenticate(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                loginSuccess: true,
                user: action.user,
                userToken: action.user.token,
            };

        case LOGIN_FAILURE:
            return {
                ...state,
                loginSuccess: false,
                error: action.response,
            };

        case TOKEN_EXPIRED_TRUE:
            return {
                ...state,
                tokenExpired: true,
            };

        case TOKEN_EXPIRED_FALSE:
            return {
                ...state,
                tokenExpired: false,
            };
        case LOGOUT:
            return {
                ...state,
                loginSuccess: null,
                user: null,
            };
        case LOGIN_SUCCESS_RESET:
            return {
                ...state,
                loginSuccess: null,
            };
        default:
            return state;
    }
}
