import React from 'react';
import LazyLoad from 'react-lazyload';

import MainNavigationBar from './MainNavigationBar';
import TopNavigationBar from './TopNavigationBar';
import NewsTicker from './NewsTicker';
import HEADER from '../../../assets/images/header.webp';
import '../../../assets/styles/Header.css';

const Header = () => (
    <header id="header">
        <TopNavigationBar />

        <div id="logo-holder">
            <div className="container-flueid">
                <a href="/">
                    <LazyLoad>
                        <img
                            className="header-banner"
                            src={HEADER}
                            alt="AskImam"
                        />
                    </LazyLoad>
                </a>
            </div>
        </div>

        <MainNavigationBar />

        <NewsTicker />
    </header>

);

export default Header;
