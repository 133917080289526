import { isEmpty } from '../utils/lodashUtils';

import {
    POST_QUESTION_FAILURE,
    POST_QUESTION_SUCCESS,
    SET_POST_QUESTION_FALSE,
    SET_POST_QUESTION_IS_LOADING,
} from '../actions/actionTypes';
import { getUser } from '../utils/utils';

const user = getUser();
const initialState = {
    postQuestionSuccess: false,
    question: null,
    userToken: !isEmpty(user) ? user.token : null,
    isLoading: false,
};

export default function postQuestion(state = initialState, action) {
    switch (action.type) {
        case POST_QUESTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                postQuestionSuccess: true,
                userToken: action.userToken,
            };

        case POST_QUESTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                postQuestionSuccess: false,
                error: action.response,
            };

        case SET_POST_QUESTION_FALSE:
            return {
                ...state,
                isLoading: false,
                postQuestionSuccess: false,
            };
        case SET_POST_QUESTION_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        default:
            return state;
    }
}
