import httpService from './httpService';

import { getUserToken, getAuthHeader } from '../utils/utils';
import {
    loginUrl,
    countryListUrl,
    daruliftaaListUrl,
    registerUrl,
    refreshTokenUrl,
} from '../constants/apiEndPoints';


export function userLoginAPI(email, password) {
    const data = { email, password };
    return httpService.post(loginUrl, data);
}

export function getCountriesListAPI() {
    return httpService.get(countryListUrl);
}

export function getDaruliftaaListAPI() {
    const headers = getAuthHeader();
    return httpService.get(daruliftaaListUrl, headers);
}

export function registerUserAPI(formData) {
    const data = formData;
    const headers = { content_type: 'multipart/form-data' };
    return httpService.post(registerUrl, data, headers);
}

export function refreshTokenAPI() {
    const userToken = getUserToken();
    const data = { userToken };
    return httpService.post(refreshTokenUrl, data);
}
