import { POST_MESSAGE_FAILURE, POST_MESSAGE_SUCCESS } from '../actions/actionTypes';

const initialState = {
    postMessageSuccess: null,
    name: null,
    email: null,
    subject: null,
    message: null,
};

export default function postMessage(state = initialState, action) {
    switch (action.type) {
        case POST_MESSAGE_SUCCESS:
            return {
                ...action.data,
                postMessageSuccess: true,
            };

        case POST_MESSAGE_FAILURE:
            return {
                ...action.data,
                postMessageSuccess: false,
                error: action.response,
            };

        default:
            return state;
    }
}
