import httpService from './httpService';
import {
    categoriesUrl, fatwasListUrl, fatwaDetailUrl, fatwaAdvanceFilterUrl,
    fatwaFilterUrl, randomFatwaUrl,
} from '../constants/apiEndPoints';

export function listCategoriesAPI() {
    return httpService.get(categoriesUrl);
}

export function listFatwasAPI(categoryId, offset) {
    return httpService.get(fatwasListUrl(categoryId, offset));
}

export function listFatwaAPI(fatwaId) {
    return httpService.get(fatwaDetailUrl(fatwaId));
}

export function listRandomFatwaAPI() {
    return httpService.get(randomFatwaUrl);
}

export function listCriteriaFilteredFatwasAPI(categoryId = '', fatwaId = '', question = '', title = '', answer = '', offset = 0) {
    return httpService.get(fatwaAdvanceFilterUrl(categoryId, fatwaId, question, title, answer, offset));
}

export function listKeywordFilteredFatwasAPI(keyword, offset) {
    return httpService.get(fatwaFilterUrl(keyword, offset));
}
