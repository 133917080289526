import { isEmpty } from './lodashUtils';

export function validateEmail(email) {
    // eslint-disable-next-line
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
        return 'Please provide a valid Email Address!';
    }
    return null;
}

export function validatePassword(password) {
    if (!password) { return 'Password must not be empty!'; }
    if (password.length < 8) { return 'Password must be atleast 8 characters long!'; }
    return null;
}

export function validateRetypePassword(password, retypePassword) {
    if (password !== retypePassword) {
        return 'Password and Retype-Password do not match!';
    }
    return null;
}

export function validateField(fieldValue) {
    if (!fieldValue) {
        return 'This field is required!';
    }
    return null;
}

export function validatePhone(number) {
    if (!/^\+?[0-9]{7,}/.test(number)) {
        return 'Please enter a valid phone number!';
    }
    return null;
}

export function validateCell(number) {
    if (number && !/^\+?[0-9]{7,}/.test(number)) {
        return 'Please enter a valid cell number!';
    }
    return null;
}

export const shouldTokenRefresh = (user) => {
    const remainingTime = (user.exp * 1000) - new Date().getTime();
    return !isEmpty(user) && remainingTime <= 1800000 && remainingTime >= 0;
};

export const isTokenExpired = (user) => {
    const expiry = user.exp || 0;
    return (expiry * 1000) - new Date().getTime() < 0;
};
