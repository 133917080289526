import { LIST_CONSTANTS_FAILURE, LIST_CONSTANTS_SUCCESS, SET_LIST_CONSTANTS_IS_LOADING } from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    listConstantsSuccess: true,
    constants: {},
};

export default function listConstants(state = initialState, action) {
    switch (action.type) {
        case LIST_CONSTANTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                constants: Object.assign({}, state.constants, {
                    [action.data.key]: action.data.value,
                }),
            };

        case LIST_CONSTANTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                listConstantsSuccess: false,
            };
        case SET_LIST_CONSTANTS_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        default:
            return state;
    }
}
