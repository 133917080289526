import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import '../../../assets/styles/Announcements.css';
import { listFeaturedAnnouncements } from '../../../actions/AnnouncementActions';
import Routes from '../../../constants/routes';
import slider1 from '../../../assets/images/slider/slider1.webp';
import slider2 from '../../../assets/images/slider/slider2.webp';
import slider3 from '../../../assets/images/slider/slider3.webp';
import slider4 from '../../../assets/images/slider/slider4.webp';

class Announcements extends Component {
    state = {
        activeSliderIndex: 0,
        activeNewsCardIndex: 0,
        timerID: null,
    };

    componentDidMount() {
        this.props.listFeaturedAnnouncements();
        const timerID = setInterval(this.moveSlider, 4000);
        this.setState({
            timerID,
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.timerID);
        this.setState({
            timerID: null,
        });
    }

    getSliderImages = () => ([
        { id: 1, image: slider1 },
        { id: 2, image: slider2 },
        { id: 3, image: slider3 },
        { id: 4, image: slider4 },
    ]);

    formatDate = date => new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(new Date(date));

    highlightNewsCard = (data) => {
        const selectedCardIndex = parseInt(data.currentTarget.dataset.index, 10);

        this.setState({
            activeSliderIndex: selectedCardIndex,
            activeNewsCardIndex: selectedCardIndex,
        });
    };

    moveSlider = () => {
        const nextSliderIndex = this.getNextSliderIndex();
        const nextNewsCardIndex = this.getNextNewsIndex();

        this.setState({
            activeSliderIndex: nextSliderIndex,
            activeNewsCardIndex: nextNewsCardIndex,
        });
    };

    getNextSliderIndex = () => {
        const sliderImageCount = this.getSliderImages().length;
        if (sliderImageCount > 0) {
            return (this.state.activeSliderIndex + 1) % sliderImageCount;
        }

        return null;
    };

    getNextNewsIndex = () => {
        const newsCount = this.props.featuredAnnouncements.length;
        if (newsCount > 0) {
            return (this.state.activeNewsCardIndex + 1) % newsCount;
        }

        return null;
    };

    render() {
        const { featuredAnnouncements } = this.props;
        const { activeSliderIndex, activeNewsCardIndex } = this.state;
        const images = this.getSliderImages();

        return (
            <div id="banner">
                <div id="slider" className="flex-slider">
                    <ul className="slides">
                        <li>
                            <LazyLoad>
                                <img
                                    className="announcement-banner show"
                                    id="announcement-banner"
                                    alt="announcement-banner"
                                    src={images[activeSliderIndex].image}
                                    data-index={activeSliderIndex}
                                />
                            </LazyLoad>
                        </li>
                    </ul>
                </div>

                <div id="carousel" className="flex-slider">
                    <div className="flex-viewport">
                        <ul id="flex-slides">
                            {
                                featuredAnnouncements
                                    .map(
                                        (announcement, index) => (
                                            <li
                                                role="presentation"
                                                className={activeNewsCardIndex === index ? 'flex-active' : ''}
                                                onClick={this.highlightNewsCard}
                                                key={announcement.id}
                                                data-index={index}
                                            >
                                                <div className="thumb">
                                                    <div
                                                        role="presentation"
                                                        style={{ cursor: 'pointer' }}
                                                        className="heading"
                                                        onClick={() => this.props.history.push(Routes.announcementDetail(announcement.id))}
                                                    >
                                                        {this.formatDate(announcement.start_date)}
                                                    </div>
                                                    <div className="content">
                                                        <Link to={Routes.announcementDetail(announcement.id)}>
                                                            {announcement.title}
                                                        </Link>
                                                    </div>
                                                    <div className="date">
                                                        {this.formatDate(announcement.start_date)}
                                                    </div>
                                                </div>
                                            </li>
                                        ),
                                    )
                            }
                        </ul>
                    </div>

                </div>
            </div>
        );
    }
}

Announcements.defaultProps = {
    featuredAnnouncements: [],
};

Announcements.propTypes = {
    featuredAnnouncements: PropTypes.any,
    history: PropTypes.any.isRequired,
    listFeaturedAnnouncements: PropTypes.func.isRequired,
};

const mapStateToProps = ({ listFeaturedAnnouncementsReducer: { featuredAnnouncements } }) => ({
    featuredAnnouncements,
});

export default connect(mapStateToProps, { listFeaturedAnnouncements })(Announcements);
