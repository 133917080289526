import {
    LIST_ANNOUNCEMENT_FAILURE,
    LIST_ANNOUNCEMENT_SUCCESS,
    LIST_ANNOUNCEMENTS_FAILURE,
    LIST_ANNOUNCEMENTS_SUCCESS,
    LIST_FEATURED_ANNOUNCEMENTS_SUCCESS,
    LIST_FEATURED_ANNOUNCEMENTS_FAILURE,
    SET_ANNOUNCEMENT_IS_LOADING,
} from '../actions/actionTypes';

const AnnouncementsInitialState = {
    announcementIntervalId: null,
    listAnnouncementsSuccess: false,
    listFeaturedAnnouncementsSuccess: false,
    announcements: [],
    featuredAnnouncements: [],
};

const AnnouncementInitialState = {
    listAnnouncementSuccess: false,
    isLoading: false,
    announcement: {},
};

export function listAnnouncements(state = AnnouncementsInitialState, action) {
    switch (action.type) {
        case LIST_ANNOUNCEMENTS_SUCCESS:
            return {
                ...state,
                announcements: action.data.results,
                listAnnouncementsSuccess: true,
            };

        case LIST_ANNOUNCEMENTS_FAILURE:
            return {
                ...state,
                listAnnouncementsSuccess: false,
            };

        default:
            return state;
    }
}

export function listFeaturedAnnouncements(state = AnnouncementsInitialState, action) {
    switch (action.type) {
        case LIST_FEATURED_ANNOUNCEMENTS_SUCCESS:
            return {
                ...state,
                featuredAnnouncements: action.data,
                listFeaturedAnnouncementsSuccess: true,
            };

        case LIST_FEATURED_ANNOUNCEMENTS_FAILURE:
            return {
                ...state,
                listFeaturedAnnouncementsSuccess: false,
            };

        default:
            return state;
    }
}

export function listAnnouncement(state = AnnouncementInitialState, action) {
    switch (action.type) {
        case LIST_ANNOUNCEMENT_SUCCESS:
            return {
                ...state,
                announcement: action.data,
                listAnnouncementSuccess: true,
                isLoading: false,
            };

        case LIST_ANNOUNCEMENT_FAILURE:
            return {
                ...state,
                announcement: {},
                listAnnouncementSuccess: false,
                isLoading: false,
            };

        case SET_ANNOUNCEMENT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        default:
            return state;
    }
}
