import { LIST_CONSTANTS_FAILURE, SET_LIST_CONSTANTS_IS_LOADING, LIST_CONSTANTS_SUCCESS } from './actionTypes';
import { listConstantsAPI } from '../api/constantsAPI';

function listConstantsSuccess(data) {
    return { data, type: LIST_CONSTANTS_SUCCESS };
}

function listConstantsFailure(response) {
    return { response, type: LIST_CONSTANTS_FAILURE };
}


export function listConstants(constantId) {
    return (dispatch) => {
        dispatch({ type: SET_LIST_CONSTANTS_IS_LOADING });

        listConstantsAPI(constantId)
            .then(response => dispatch(listConstantsSuccess(response.data)))
            .catch(response => dispatch(listConstantsFailure(response)));
    };
}
