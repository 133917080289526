import React from 'react';
import PropTypes from 'prop-types';

import { Col } from '../../utils/bootstrapUtils';
import '../../assets/styles/Common.css';
import '../../assets/styles/Subscription.css';
import SubscriptionHeader from '../common/Header/SubscriptionHeader';
import SubscriptionFooter from '../common/Footer/SubscriptionFooter';

const SubscriptionLayout = ({ children }) => (
    <div>
        <SubscriptionHeader />
        <div className="container-fluid full-page-container">
            <Col md={12} className="custom-column">
                <div
                    className="subscription-form"
                >
                    {children}
                </div>
            </Col>
        </div>
        <SubscriptionFooter />
    </div>

);

SubscriptionLayout.defaultProps = {
    children: {},
};

SubscriptionLayout.propTypes = {
    children: PropTypes.any,
};

export default SubscriptionLayout;
