import { SUBSCRIPTION_SUCCESS, SUBSCRIPTION_FAILURE, SUBSCRIPTION_IS_LOADING } from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    SubscriptionSuccess: null,
    email: null,
    error: null,
};

export default function subscribe(state = initialState, action) {
    switch (action.type) {
        case SUBSCRIPTION_SUCCESS:
            return {
                ...action.data,
                SubscriptionSuccess: true,
                isLoading: false,
            };

        case SUBSCRIPTION_FAILURE:
            return {
                ...action.data,
                error: action.response.data,
                SubscriptionSuccess: false,
                isLoading: false,
            };

        case SUBSCRIPTION_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        default:
            return state;
    }
}
