import {
    LIST_ANNOUNCEMENT_FAILURE,
    LIST_ANNOUNCEMENT_SUCCESS,
    LIST_ANNOUNCEMENTS_FAILURE,
    LIST_ANNOUNCEMENTS_SUCCESS,
    LIST_FEATURED_ANNOUNCEMENTS_SUCCESS,
    LIST_FEATURED_ANNOUNCEMENTS_FAILURE,
    SET_ANNOUNCEMENT_IS_LOADING,
} from './actionTypes';
import { listAnnouncementAPI, listAnnouncementsAPI, listFeaturedAnnouncementsAPI } from '../api/announcementAPI';

function listAnnouncementsSuccess(data) {
    return { data, type: LIST_ANNOUNCEMENTS_SUCCESS };
}

function listAnnouncementsFailure(response) {
    return { response, type: LIST_ANNOUNCEMENTS_FAILURE };
}

export function listAnnouncements() {
    return dispatch => listAnnouncementsAPI()
        .then(response => dispatch(listAnnouncementsSuccess(response.data)))
        .catch(response => dispatch(listAnnouncementsFailure(response)));
}

function listFeaturedAnnouncementsSuccess(data) {
    const topFourAnnouncements = data.slice(0, 4);
    return { data: topFourAnnouncements, type: LIST_FEATURED_ANNOUNCEMENTS_SUCCESS };
}


function listFeaturedAnnouncementsFailure(response) {
    return { response, type: LIST_FEATURED_ANNOUNCEMENTS_FAILURE };
}

export function listFeaturedAnnouncements() {
    return dispatch => listFeaturedAnnouncementsAPI()
        .then(response => dispatch(listFeaturedAnnouncementsSuccess(response.data)))
        .catch(response => dispatch(listFeaturedAnnouncementsFailure(response)));
}

function listAnnouncementSuccess(data) {
    return { data, type: LIST_ANNOUNCEMENT_SUCCESS };
}

function listAnnouncementFailure(response) {
    return { response, type: LIST_ANNOUNCEMENT_FAILURE };
}

export function listAnnouncement(announcementId) {
    return (dispatch) => {
        dispatch({ type: SET_ANNOUNCEMENT_IS_LOADING });
        listAnnouncementAPI(announcementId)
            .then(response => dispatch(listAnnouncementSuccess(response.data)))
            .catch(response => dispatch(listAnnouncementFailure(response)));
    };
}
