import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import { Col } from '../../utils/bootstrapUtils';
import '../../assets/styles/Common.css';
import Footer from '../common/Footer/Footer';
import { DefaultLoader, SingleColumnLayoutLoader } from '../common/Loader/index';

const Header = loadable(() => import(/* webpackChunkName: "sidebar" */ '../common/Header/Header'), { fallback: <DefaultLoader /> });
const SideBar = loadable(() => import(/* webpackChunkName: "sidebar" */ '../common/SideBar/SideBar'), { fallback: <SingleColumnLayoutLoader /> });


const DefaultLayout = ({ children }) => (
    <div>
        <Header />
        <div className="container child-wrapper">
            <Col md={8}>
                {children}
            </Col>
            <Col md={4}>
                <SideBar pathname={children.props.location.pathname} />
            </Col>
        </div>
        <Footer />
    </div>
);

DefaultLayout.propTypes = {
    children: PropTypes.any.isRequired,
};

export default DefaultLayout;
