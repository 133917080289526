import { FATWAS_PER_PAGE, NEWSLETTERS_PER_PAGE } from './globals';

const { REACT_APP_API_URL } = process.env;

export const announcementsListUrl = `${REACT_APP_API_URL}/api/v1/news/announcements/`;
export const featuredAnnouncementsListUrl = `${REACT_APP_API_URL}/api/v1/news/announcements/featured/`;
export const announcementDetailUrl = announcementId => `${REACT_APP_API_URL}/api/v1/news/announcements/${announcementId}`;

export const getPageHtmlUrl = pageId => `${REACT_APP_API_URL}/api/v1/constance/?ck=${pageId}`;

export const countryListUrl = `${REACT_APP_API_URL}/api/v1/core/countries/`;
export const daruliftaaListUrl = `${REACT_APP_API_URL}/api/v1/fatwas/daruliftaas/`;

export const resetPasswordLinkUrl = `${REACT_APP_API_URL}/api/v1/user/send-reset-password-link/`;
export const resetPasswordUrl = `${REACT_APP_API_URL}/api/v1/user/reset-password/`;
export const loginUrl = `${REACT_APP_API_URL}/api/v1/user/login/`;
export const registerUrl = `${REACT_APP_API_URL}/api/v1/user/register/`;
export const refreshTokenUrl = `${REACT_APP_API_URL}/api/v1/user/token-refresh/`;

// export const hadithListUrl = offset => `${REACT_APP_API_URL}/api/v1/hadith?limit=25&offset=${offset}`;
export const hadithListUrl = offset => `${REACT_APP_API_URL}/api/v1/fatwas/fatwas/?cid=13&limit=25&offset=${offset}`;
// export const hadithDetailUrl = hadithID => `${REACT_APP_API_URL}/api/v1/hadith/${hadithID}`;
export const hadithDetailUrl = hadithID => `${REACT_APP_API_URL}/api/v1/fatwas/fatwas/${hadithID}`;

export const sendMessageUrl = `${REACT_APP_API_URL}/api/v1/contact/`;

export const postQuestionUrl = `${REACT_APP_API_URL}/api/v1/fatwas/questions/`;
export const sideWidgetImagesUrl = `${REACT_APP_API_URL}/api/v1/side-widget-images/`;
export const sliderImagesUrl = `${REACT_APP_API_URL}/api/v1/news/slider-images/`;
export const currencyUrl = `${REACT_APP_API_URL}/api/v1/currency/`;

export const researchListUrl = offset => `${REACT_APP_API_URL}/api/v1/researches?limit=25&offset=${offset}`;

export const categoriesUrl = `${REACT_APP_API_URL}/api/v1/fatwas/categories/`;

export const fatwasListUrl = (categoryId, offset) => {
    let url = `${REACT_APP_API_URL}/api/v1/fatwas/fatwas/?limit=${FATWAS_PER_PAGE}&offset=${offset}`;

    if (categoryId !== 0) {
        url = `${REACT_APP_API_URL}/api/v1/fatwas/fatwas/?cid=${categoryId}&limit=${FATWAS_PER_PAGE}&offset=${offset}`;
    }

    return url;
};

export const fatwaDetailUrl = fatwaId => `${REACT_APP_API_URL}/api/v1/fatwas/fatwas/${fatwaId}/`;
export const randomFatwaUrl = `${REACT_APP_API_URL}/api/v1/random/`;

export const fatwaAdvanceFilterUrl = (categoryId = '', fatwaId = '', question = '', title = '', answer = '', offset = 0) => {
    const offsetParam = offset ? `&offset=${offset}` : '';
    return `${REACT_APP_API_URL}/api/v1/search/?cid=${categoryId}&fid=${fatwaId}&ques=${question}&title=${title}&answer=${answer}${offsetParam}`;
};

export const fatwaFilterUrl = (keyword, offset) => {
    const offsetParam = offset ? `&offset=${offset}` : '';
    return `${REACT_APP_API_URL}/api/v1/search/?kw=${keyword}${offsetParam}`;
};

export const subscribeUrl = `${REACT_APP_API_URL}/api/v1/subscribe/`;
export const unSubscribeUrl = uuid => `${REACT_APP_API_URL}/api/v1/subscribe/${uuid}/`;
export const subscriptionDetailUrl = uuid => `${REACT_APP_API_URL}/api/v1/subscribe/${uuid}/`;

export const newsletterListUrl = offset => `${REACT_APP_API_URL}/api/v1/newsletters/?limit=${NEWSLETTERS_PER_PAGE}&offset=${offset}`;
export const newsletterDetailUrl = newsletterId => `${REACT_APP_API_URL}/api/v1/newsletters/${newsletterId}`;

export const audioListUrl = `${REACT_APP_API_URL}/api/v1/multimedia`;
