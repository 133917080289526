import { SET_SEARCH_BAR_KEYWORD } from '../actions/actionTypes';

const initialState = {
    keyword: '',
};

export default function searchBarReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SEARCH_BAR_KEYWORD:
            return {
                ...state,
                keyword: action.keyword,
            };
        default:
            return state;
    }
}
