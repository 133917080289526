import { LIST_AUDIOS_SUCCESS, LIST_AUDIOS_FAILURE, LIST_AUDIOS_IS_LOADING } from '../actions/actionTypes';

const audiosInitialState = {
    audiosList: [],
    isLoading: false,
    error: null,
};

export function listAudiosReducer(state = audiosInitialState, action) {
    switch (action.type) {
        case LIST_AUDIOS_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case LIST_AUDIOS_SUCCESS:
            return {
                ...state,
                audiosList: action.data.results,
                isLoading: false,
            };
        case LIST_AUDIOS_FAILURE:
            return {
                ...state,
                audios: [],
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
