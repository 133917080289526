import { LIST_DARULIFTAA, LIST_DARULIFTAA_FAILURE, LIST_DARULIFTAA_SUCCESS } from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    listDaruliftaaSuccess: true,
    daruliftaas: [],
};

export default function listDaruliftaa(state = initialState, action) {
    switch (action.type) {
        case LIST_DARULIFTAA:
            return {
                ...state,
                isLoading: true,
            };
        case LIST_DARULIFTAA_SUCCESS:
            return {
                ...state,
                daruliftaas: action.response.data,
                isLoading: false,
            };

        case LIST_DARULIFTAA_FAILURE:
            return {
                ...state,
                listDaruliftaaSuccess: false,
                isLoading: false,
            };

        default:
            return state;
    }
}
