import queryString from 'query-string';
import { scroller } from 'react-scroll';
import parseJwt from './parseJWT';
import { USER } from '../constants/globals';

const islamicMonths = [
    'Muharram', 'Safar', 'Rabi\'ul Awwal', 'Rabi\'ul Akhir',
    'Jumada al-ula', 'Jumadal Akhira', 'Rajab', 'Sha\'ban',
    'Ramadan', 'Shawwal', 'Dhul Qa\'ada', 'Dhul Hijja',
];

function gmod(n, m) {
    return ((n % m) + m) % m;
}

function kuwaiticalendar(adjust) {
    let today = new Date();
    if (adjust) {
        const adjustmili = 1000 * 60 * 60 * 24 * adjust;
        const todaymili = today.getTime() + adjustmili;
        today = new Date(todaymili);
    }
    // SA day adjustment
    let day = today.getDate() - 1;
    let month = today.getMonth();
    let year = today.getFullYear();
    let m = month + 1;
    let y = year;
    if (m < 3) {
        y -= 1;
        m += 12;
    }

    let a = Math.floor(y / 100.0);
    let b = 2 - a + Math.floor(a / 4.0);
    if (y < 1583) b = 0;
    if (y === 1582) {
        if (m > 10) b = -10;
        if (m === 10) {
            b = 0;
            if (day > 4) b = -10;
        }
    }

    const jd = Math.floor(365.25 * (y + 4716)) + Math.floor(30.6001 * (m + 1)) + day + b - 1524;

    b = 0;
    if (jd > 2299160) {
        a = Math.floor((jd - 1867216.25) / 36524.25);
        b = 1 + a - Math.floor(a / 4.0);
    }
    const bb = jd + b + 1524;
    let cc = Math.floor((bb - 122.1) / 365.25);
    const dd = Math.floor(365.25 * cc);
    const ee = Math.floor((bb - dd) / 30.6001);
    day = (bb - dd) - Math.floor(30.6001 * ee);
    month = ee - 1;
    if (ee > 13) {
        cc += 1;
        month = ee - 13;
    }
    year = cc - 4716;

    let wd = 0;

    if (adjust) {
        wd = gmod(jd + 1 - adjust, 7) + 1;
    } else {
        wd = gmod(jd + 1, 7) + 1;
    }

    const iyear = 10631.0 / 30.0;
    const epochastro = 1948084;
    // const epochcivil = 1948085;

    const shift1 = 8.01 / 60.0;

    let z = jd - epochastro;
    const cyc = Math.floor(z / 10631.0);
    z -= 10631 * cyc;
    const j = Math.floor((z - shift1) / iyear);
    const iy = 30 * cyc + j;
    z -= Math.floor(j * iyear + shift1);
    let im = Math.floor((z + 28.5001) / 29.5);
    if (im === 13) im = 12;
    const id = z - Math.floor(29.5001 * im - 29);

    const myRes = new Array(8);

    myRes[0] = day; // calculated day (CE)
    myRes[1] = month - 1; // calculated month (CE)
    myRes[2] = year; // calculated year (CE)
    myRes[3] = jd - 1; // julian day number
    myRes[4] = wd - 1; // weekday number
    myRes[5] = id; // islamic date
    myRes[6] = im - 1; // islamic month
    myRes[7] = iy; // islamic year

    return myRes;
}

export function writeIslamicDate(adjustment) {
    const iMonthNames = islamicMonths;
    const iDate = kuwaiticalendar(adjustment);
    return ` ${iDate[5]} ${iMonthNames[iDate[6]]} ${iDate[7]}`;
}

export function convertToInteger(value) {
    return parseInt(value, 10);
}

export function createUserInfo(token, rememberMe) {
    const userInfo = parseJwt(token);
    userInfo.token = token;
    userInfo.rememberMe = rememberMe;
    return userInfo;
}

export function setUser(userInfo) {
    localStorage.setItem(USER, JSON.stringify(userInfo));
}

export function getUser() {
    return JSON.parse(localStorage.getItem(USER) || '{}');
}

export function getUserToken() {
    return JSON.parse(localStorage.getItem(USER)).token;
}

export function getAuthHeader() {
    return { authorization: `Bearer ${getUserToken()}` };
}

export function removeUser() {
    localStorage.removeItem(USER);
}

export function scrollToElement(element) {
    element.scrollIntoView(true);
}

export function isElementVisible(element) {
    const bounding = element.getBoundingClientRect();
    return bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight);
}

export function scrollToElementWithAnimation(elementId, duration = 750) {
    scroller.scrollTo(elementId, {
        smooth: true,
        duration,
    });
}

export function scrollToNavigation() {
    const navigationBar = document.getElementById('navbar');
    if (navigationBar && !isElementVisible(navigationBar)) scrollToElement(navigationBar);
}

export function computeOffsetFromPageNo(currentPage, pageLimit) {
    return Math.ceil((currentPage - 1) * pageLimit);
}

export const extractParamsFromUrl = () => queryString.parse(window.location.search);

export const extractSubString = (text, start = 0, end = null) => {
    let extractedString = '';
    if (typeof text === 'string') {
        extractedString = end ? text.substr(start, end) : text.substr(start);
    }

    return extractedString;
};
