import {
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGIN_SUCCESS_RESET,
    LOGOUT, TOKEN_EXPIRED_FALSE,
    TOKEN_EXPIRED_TRUE,
} from './actionTypes';
import { refreshTokenAPI, userLoginAPI } from '../api/userAPI';
import Routes from '../constants/routes';
import { createUserInfo, setUser, getUser, removeUser } from '../utils/utils'; // eslint-disable-line
import { isTokenExpired } from '../utils/validators';

export function loginSuccess(user) { return { user, type: LOGIN_SUCCESS }; }
export function loginFailure(response) { return { response, type: LOGIN_FAILURE }; }

export const loginSuccessReset = {
    type: LOGIN_SUCCESS_RESET,
};

export function userLoggingin(email, password, rememberMe, history) {
    return (dispatch) => {
        userLoginAPI(email, password)
            .then((response) => {
                const user = createUserInfo(response.data.token, rememberMe);
                setUser(user);

                dispatch(loginSuccess(user));
                history.push(Routes.askQuestion);
            })
            .catch((error) => {
                dispatch(loginFailure(error.response));
            });
    };
}

export function refreshToken() {
    const user = getUser();
    if (user.token && isTokenExpired(user) && user.rememberMe) {
        refreshTokenAPI()
            .then((response) => {
                const userInfo = createUserInfo(response.data.token, user.rememberMe);
                setUser(userInfo);
            })
            .catch((error) => {
                console.error('error occurred on refreshing token', error);
            });
    }
}

export function logout(history) {
    removeUser();
    history.push('/');
    return { type: LOGOUT };
}

export const setTokenExpiredTrue = {
    type: TOKEN_EXPIRED_TRUE,
};

export const setTokenExpiredFalse = {
    type: TOKEN_EXPIRED_FALSE,
};
