import ReactGA from 'react-ga';
import { REACT_APP_GA_TRACKING_ID } from '../settings';

const googleAnalyticsActions = {};

googleAnalyticsActions.initGoogleAnalytics = async () => {
    ReactGA.initialize(REACT_APP_GA_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
};

export { googleAnalyticsActions };
