import httpService from './httpService';
import { announcementsListUrl, featuredAnnouncementsListUrl, announcementDetailUrl } from '../constants/apiEndPoints';

export function listAnnouncementsAPI() {
    return httpService.get(announcementsListUrl);
}

export function listFeaturedAnnouncementsAPI() {
    return httpService.get(featuredAnnouncementsListUrl);
}

export function listAnnouncementAPI(announcementId) {
    return httpService.get(announcementDetailUrl(announcementId));
}
