import React from 'react';

import FooterLinks from './FooterLinks';
import '../../../assets/styles/Footer.css';

const Footer = () => (
    <div>
        <section id="parallax">
            <div className="content">
                <p>
                    The Messenger of Allah ﷺ said,
                    "When Allah wishes good for someone,
                    He bestows upon him the understanding of Deen."
                </p>
                <p>[Al-Bukhari and Muslim]</p>
            </div>
        </section>
        <footer id="footer">
            <FooterLinks />
        </footer>
    </div>
);

export default Footer;
