import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import DefaultLayout from './DefaultLayout';

const DefaultLayoutRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={matchProps => <DefaultLayout><Component {...matchProps} /></DefaultLayout>}
    />
);

DefaultLayoutRoute.propTypes = {
    component: PropTypes.any.isRequired,
};

export default DefaultLayoutRoute;
