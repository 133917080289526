import React from 'react';

import FooterLogo from './FooterLogo';
import FooterLinks from './FooterLinks';
import '../../../assets/styles/Footer.css';

const SubscriptionFooter = () => (
    <div>
        <footer id="footer">
            <FooterLogo />
            <FooterLinks />
        </footer>
    </div>
);

export default SubscriptionFooter;
