import React, { Component } from 'react';
import FileViewer from 'react-file-viewer';
import {
    FacebookShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
    LineShareButton,
    TelegramShareButton,
    WhatsappIcon,
    FacebookIcon,
    TwitterIcon,
    ViberIcon,
    LineIcon,
    TelegramIcon,
} from 'react-share';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import { CircularProgress } from '../../utils/materialUI';
import { Col, Row } from '../../utils/bootstrapUtils';
import '../../assets/styles/Fatwa.css';
import { listFatwa, listRandomFatwa, setFatwaIsLoadingTrue } from '../../actions/FatwaActions';
import { listConstants } from '../../actions/ConstantsActions';
import LOGO from '../../assets/images/logo-thumbnail.png';
import { HTML_PAGE } from '../../constants/globals';

class Fatwa extends Component {
    componentDidMount() {
        const fatwaId = this.props.match.params.fatwaId;

        if (fatwaId === 'random') {
            this.props.listRandomFatwa();
        } else {
            this.props.listFatwa(fatwaId);
        }
        this.props.setFatwaIsLoadingTrue();

        this.props.listConstants(HTML_PAGE.DISCLAIMER);
        document.title = 'Public » Askimam';
    }

    renderAttachment = (attachment) => {
        if (attachment) {
            const type = attachment.substr(attachment.lastIndexOf('.') + 1);
            const imageExtensions = ['jpg', 'jpeg', 'png'];

            if (imageExtensions.includes(type)) {
                return (
                    <img
                        className="fatwa-image"
                        src={attachment}
                        alt="question"
                    />
                );
            }
            return (
                <FileViewer
                    className="fatwa-image"
                    fileType={attachment
                        .substr(attachment.lastIndexOf('.') + 1)}
                    filePath={attachment}
                />
            );
        }
        return '';
    }

    renderFatwa() {
        const { fatwa } = this.props.fatwa;

        if (!fatwa.id && !this.props.fatwa.isLoading) {
            return (
                <div className="result-not-found">
                    No question found. Please refine your search criteria.
                </div>
            );
        }

        return (
            <div className="fatwa-holder">
                <div id="qa-header">
                    <Row>
                        <Col md={2} className="qa-headers">
                            <span className="qa-header-heading">
                                Fatwa: #
                            </span>
                            <span className="qa-header-content">
                                {fatwa.old_id}
                            </span>
                        </Col>
                        <Col md={4} className="qa-headers">
                            <span className="qa-header-heading">
                                Category:
                            </span>
                            <span className="qa-header-content">
                                {fatwa.answer.category.name}
                            </span>
                        </Col>
                        <Col md={3} className="qa-headers">
                            <span className="qa-header-heading">
                                Country:
                            </span>
                            <span className="qa-header-content">
                                {fatwa.answer.question.country_name}
                            </span>
                        </Col>
                        <Col md={3} className="qa-headers">
                            <span className="qa-header-heading">
                                Date:
                            </span>
                            <span className="qa-header-content">
                                {
                                    new Intl.DateTimeFormat('en-GB', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }).format(new Date(fatwa.answer.answer_modified))
                                }
                            </span>
                        </Col>
                    </Row>
                </div>
                <div className="question-panel">
                    <div className="question-title">
                        <div className="panel-header">
                            <div className="image-holder">
                                <img className="logo-image" src={LOGO} alt="AskImam" />
                            </div>
                            Title
                        </div>
                    </div>

                    <div className="qa-content-title">
                        <h4 dangerouslySetInnerHTML={{ __html: fatwa.answer.question.title }} />
                    </div>
                </div>

                <div className="question-answer">

                    {/* Question and Answer */}

                    <div className="question-panel">
                        <div className="question-title">
                            <div className="panel-header">
                                <div className="image-holder">
                                    <img className="logo-image" src={LOGO} alt="AskImam" />
                                </div>
                                Question
                            </div>
                        </div>
                        <div className="question-content">
                            <div className="qa-content">
                                <h4
                                    dangerouslySetInnerHTML={{ __html: fatwa.answer.question.question }}
                                />
                            </div>
                        </div>
                        {
                            fatwa.answer.question.attachment ?
                                this.renderAttachment(fatwa.answer.question.attachment)
                                : ''
                        }
                    </div>

                    <div className="answer-panel">
                        <div className="answer-title">
                            <div className="panel-header">
                                <div className="image-holder">
                                    <img className="logo-image" src={LOGO} alt="AskImam" />
                                </div>
                                Answer
                            </div>
                        </div>
                        <br />
                        <div className="sharebuttons">
                            <h5>Share:</h5>
                            <WhatsappShareButton url={window.location.href}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <FacebookShareButton url={window.location.href}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton url={window.location.href}>
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <ViberShareButton url={window.location.href}>
                                <ViberIcon size={32} round />
                            </ViberShareButton>
                            <TelegramShareButton url={window.location.href}>
                                <TelegramIcon size={32} round />
                            </TelegramShareButton>
                            <LineShareButton url={window.location.href}>
                                <LineIcon size={32} round />
                            </LineShareButton>
                        </div>
                        <div className="answer-content">
                            <div className="qa-content">
                                <div dangerouslySetInnerHTML={{ __html: fatwa.answer.answer }} />
                            </div>
                            <br />

                            {
                                fatwa.answer.attachment ?
                                    this.renderAttachment(fatwa.answer.attachment)
                                    : ''
                            }
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    renderDisclaimer() {
        const disclaimerInfo = this.props.constants.constants;

        if (!disclaimerInfo) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div
                className="footer-disclaimer"
                dangerouslySetInnerHTML={{ __html: disclaimerInfo[HTML_PAGE.DISCLAIMER] }}
            />
        );
    }

    render() {
        scroller.scrollTo('fatwa-page', {
            smooth: true,
            duration: 750,
        });

        return (
            <div className="fatwa-page">

                <div className="fatwa">
                    {
                        this.props.fatwa.isLoading ?
                            (
                                <div className="loader">
                                    <CircularProgress color="inherit" />
                                </div>
                            )
                            : this.renderFatwa()
                    }
                </div>

                {this.renderDisclaimer()}

            </div>
        );
    }
}

Fatwa.propTypes = {
    fatwa: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    constants: PropTypes.object.isRequired,
    listRandomFatwa: PropTypes.func.isRequired,
    listFatwa: PropTypes.func.isRequired,
    setFatwaIsLoadingTrue: PropTypes.func.isRequired,
    listConstants: PropTypes.func.isRequired,
};

const mapStateToProps = ({ listFatwaReducer, listConstantsReducer }) => ({
    fatwa: listFatwaReducer,
    constants: listConstantsReducer,
});

export default connect(mapStateToProps, {
    listFatwa,
    listRandomFatwa,
    listConstants,
    setFatwaIsLoadingTrue,
})(Fatwa);
