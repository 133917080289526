export const FATWAS_PER_PAGE = 42;
export const NEWSLETTERS_PER_PAGE = 5;

export const HTML_PAGE = {
    ABOUT: 'aboutus',
    SCHOLARS: 'mufti_info',
    CONTACT_US: 'contactus',
    DISCLAIMER: 'disclaimer',
    ASK_QUESTION: 'askquestion',
    AFFILIATED_SITES: 'affiliated_sites',
};

export const USER = 'user';
export const YOUTUBE_CHANNEL_URL = 'https://www.youtube.com/c/daruliftaam';
export const YOUTUBE_WIDGET_VIDEO_URL = 'https://www.youtube.com/embed/WM_sbDOq_ro';
export const WHATS_APP_URL = 'https://wa.me/27673474561';
