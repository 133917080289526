import React from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

import { CircularProgress } from '../../utils/materialUI';
import { Col, Row } from '../../utils/bootstrapUtils';
import '../../assets/styles/Fatwa.css';
import INK_ICON from '../../assets/images/latest-fatwa.svg';
import LOGO_SMALL from '../../assets/images/logo-50x51.png';
import Routes from '../../constants/routes';
import '../../assets/styles/index.css';
import { FATWAS_PER_PAGE } from '../../constants/globals';
import { groupBy } from '../../utils/lodashUtils';

const Fatwas = (props) => {
    const {
        isLoading, fatwas, isAdvanceSearchForm, currentPage, heading, handlePageClick,
    } = props;

    const totalPages = fatwas ? Math.ceil(fatwas.count / FATWAS_PER_PAGE) : 0;

    const groupedFatwas = fatwas ? groupBy(fatwas.results, value => new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(new Date(value.answer.answer_modified))) : {};

    const fatwasCount = Object.keys(groupedFatwas).length;

    return (
        <div id="fatwas-list" className="fatwas-list">
            <Row>
                <Col md={12}>
                    <h2 className="category-heading heading">
                        <img
                            src={INK_ICON}
                            className="latest-fatwas-image"
                            alt="Not available"
                        />
                        {heading}
                    </h2>
                </Col>
            </Row>
            {
                isLoading ?
                    (
                        !isAdvanceSearchForm ?
                            <div className="loader">
                                <CircularProgress color="inherit" />
                            </div>
                            : ''
                    )
                    : (
                        <div className="questions-holder">
                            {
                                fatwasCount > 0 ?
                                    Object.keys(groupedFatwas)
                                        .map(date => (
                                            <div
                                                key={date}
                                                className="panel"
                                            >
                                                <div className="panel-header">
                                                    <div className="image-holder">
                                                        <img
                                                            className="logo-image"
                                                            src={LOGO_SMALL}
                                                            alt="AskImam"
                                                        />
                                                    </div>
                                                    {
                                                        date
                                                    }
                                                </div>

                                                <div className="panel-content">
                                                    <ul className="questions">
                                                        {
                                                            groupedFatwas[date].map(fatwa => (
                                                                <li
                                                                    key={fatwa.id}
                                                                    className="question"
                                                                >
                                                                    <Link
                                                                        className="question_link"
                                                                        to={Routes.questionDetail(fatwa.old_id)}
                                                                    >
                                                                        {fatwa.answer.question.title}
                                                                    </Link>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        ))
                                    :
                                    !isLoading && (
                                        <div className="result-not-found">
                                            {
                                                isAdvanceSearchForm ?
                                                    'No question found. Please refine your search criteria.'
                                                    : 'No fatawas are found.'
                                            }
                                        </div>
                                    )
                            }
                            {
                                fatwasCount > 0 ?
                                    <div className="pagination-wrapper">
                                        <ReactPaginate
                                            pageCount={totalPages}
                                            pageRangeDisplayed={8}
                                            marginPagesDisplayed={3}
                                            forcePage={currentPage - 1}
                                            onPageChange={handlePageClick}
                                            previousLabel="<"
                                            nextLabel=">"
                                            breakLabel="..."
                                            containerClassName="pages"
                                            pageClassName="page"
                                            previousClassName="page previous"
                                            nextClassName="page next"
                                            breakClassName="page break"
                                            pageLinkClassName="page-link"
                                            activeLinkClassName="active"
                                            previousLinkClassName="page-link previous"
                                            nextLinkClassName="page-link next"
                                            breakLinkClassName="page-link break"
                                        />
                                    </div>
                                    : ''
                            }

                        </div>
                    )
            }

        </div>
    );
};

export default Fatwas;
