import {
    LIST_SIDE_WIDGETS_FAILURE,
    LIST_SIDE_WIDGETS_SUCCESS,
    SET_SIDE_WIDGETS_IMAGES_IS_LOADING,
} from '../actions/actionTypes';

const sideWidgetImagesInitialState = {
    isLoading: false,
    listSideWidgetImagesSuccess: false,
    images: {},
};

export default function listSideWidgetImages(state = sideWidgetImagesInitialState, action) {
    switch (action.type) {
        case LIST_SIDE_WIDGETS_SUCCESS: {
            const images = {};
            action.data.forEach((value) => {
                images[value.side_widget_name] = value.image;
            });
            return {
                ...state,
                listSideWidgetImagesSuccess: true,
                images,
                isLoading: false,
            };
        }

        case LIST_SIDE_WIDGETS_FAILURE:
            return {
                ...state,
                isLoading: false,
                listSideWidgetImagesSuccess: false,
            };

        case SET_SIDE_WIDGETS_IMAGES_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        default:
            return state;
    }
}
