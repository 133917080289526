import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../assets/styles/Fatwa.css';
import { listFatwas } from '../../actions/FatwaActions';
import { setSubmittedFalse } from '../../actions/QuestionActions';
import Routes from '../../constants/routes';
import '../../assets/styles/index.css';
import { computeOffsetFromPageNo, scrollToElementWithAnimation } from '../../utils/utils';
import { FATWAS_PER_PAGE } from '../../constants/globals';
import Fatwas from './Fatwas';

const FatwasList = (props) => {
    const { params } = props.match;
    const [catId, setCatId] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        const { categoryId = '', page = 1 } = params;

        let timeout = null;
        if (props.postQuestion.postQuestionSuccess === true) {
            timeout = setTimeout(() => {
                props.setSubmittedFalse();
            }, 5000);
        }

        if (categoryId !== catId || page !== currentPage) {
            const offset = computeOffsetFromPageNo(page, FATWAS_PER_PAGE);
            props.listFatwas(categoryId, offset);
            setCatId(categoryId);
            setCurrentPage(page);
        }

        if (timeout) {
            return () => clearTimeout(timeout);
        }
        // eslint-disable-next-line
    }, [params, catId, currentPage, props.listFatwas]);

    const handlePageClick = (data) => {
        const page = data.selected + 1;
        let currentUrl = window.location.pathname;
        currentUrl += currentUrl.slice(-1) === '/' ? '' : '/';

        scrollToElementWithAnimation('fatwas-list');

        props.history.push({
            pathname: `${currentUrl.split('page:')[0]}page:${page}`,
        });
    };

    const { listFatwasReducer, match } = props;
    const { fatwas = [], isLoading } = listFatwasReducer;

    const getHeading = () => {
        let pageHeading = 'Latest Fatawa';
        if (match.path.includes(Routes.fatwasByCategory) && fatwas.category) {
            pageHeading = `Category: ${fatwas.category || ''}`;
        }
        return pageHeading;
    };

    const heading = getHeading();

    useMemo(() => {
        document.title = match.path === '/' ? 'Fatwas by Mufti Ebrahim Desai » Askimam' : `${heading} » Askimam`;
    }, [match.path, heading]);

    return (
        <div>
            {
                props.postQuestion.postQuestionSuccess
                && <div className="flashMessage">Question submitted successfully</div>
            }

            <Fatwas
                heading={heading}
                isLoading={isLoading}
                fatwas={fatwas}
                currentPage={params?.page || 1}
                handlePageClick={handlePageClick}
            />
        </div>
    );
};

FatwasList.defaultProps = {
    categoryId: '',
    fatwas: null,
};

FatwasList.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    postQuestion: PropTypes.object.isRequired,
    categoryId: PropTypes.any,
    fatwas: PropTypes.object,
    setSubmittedFalse: PropTypes.func.isRequired,
    listFatwas: PropTypes.func.isRequired,
};

const mapStateToProps = ({ listFatwasReducer, postQuestionReducer }) => ({
    listFatwasReducer,
    postQuestion: postQuestionReducer,
});

export default connect(mapStateToProps, {
    listFatwas,
    setSubmittedFalse,
})(FatwasList);
