import React from 'react';

import { Col, Row } from '../../../utils/bootstrapUtils';
import LOGO from '../../../assets/images/logo-thumbnail.png';
import { YOUTUBE_CHANNEL_URL } from '../../../constants/globals';


const FooterLogo = () => (
    <div>
        <div className="footer-logo">
            <div className="container">
                <Row>
                    <Col className="footer-logo-links">
                        <span>
                            <img className="footer-logo-image" src={LOGO} alt="Ask Imam Logo" />
                            <span className="footer-text">ASK IMAM</span>
                        </span>
                        <span>
                            <a
                                href="https://twitter.com/@Darul_iftaa"
                                className="footer-icon-link twitter-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-twitter" />
                            </a>
                            <a
                                href={YOUTUBE_CHANNEL_URL}
                                className="footer-icon-link youtube-play-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-youtube-play" />
                            </a>
                            <a
                                href="https://web.whatsapp.com"
                                className="footer-icon-link whatsapp-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-whatsapp" />
                            </a>
                        </span>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
);

export default FooterLogo;
