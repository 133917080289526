import {
    POST_QUESTION_FAILURE,
    POST_QUESTION_SUCCESS,
    SET_POST_QUESTION_FALSE,
    SET_POST_QUESTION_IS_LOADING,
} from './actionTypes';
import { postQuestionAPI } from '../api/questionAPI';

export function postQuestionSuccess(data) { return { data, type: POST_QUESTION_SUCCESS }; }
export function postQuestionFailure(response) { return { response, type: POST_QUESTION_FAILURE }; }
export const setPostQuestionSubmitFalse = { type: SET_POST_QUESTION_FALSE };

export function postQuestion(question, setSubmitted) {
    return (dispatch) => {
        dispatch({ type: SET_POST_QUESTION_IS_LOADING });
        postQuestionAPI(question)
            .then((response) => {
                setSubmitted();
                dispatch(postQuestionSuccess(response, question));
            })
            .catch(error => dispatch(postQuestionFailure(error.response)));
    };
}

export function setSubmittedFalse() {
    return dispatch => dispatch(setPostQuestionSubmitFalse);
}
