import React from 'react';
import { Link } from 'react-router-dom';

import { Col, Row } from '../../../utils/bootstrapUtils';
import Routes from '../../../constants/routes';


const FooterLinks = () => (
    <div>
        <div className="footer-links">
            <div className="container">
                <Row>
                    <Col sm={3} className="custom-col-6">
                        <h2>About</h2>
                        <ul className="bottom-links">
                            <li><Link to={Routes.about}>About</Link></li>
                            <li><Link to={Routes.darulIftaa}>Affiliated Sites</Link></li>
                            <li><Link to={Routes.disclaimer}>Disclaimer</Link></li>
                            <li><Link to={Routes.scholars}>Scholars</Link></li>
                        </ul>
                    </Col>

                    <Col sm={3} className="custom-col-6">
                        <h2>Connect</h2>
                        <ul className="bottom-links">
                            <li><Link to={Routes.contact}>Contact Us</Link></li>
                            <li>
                                <a
                                    href="https://twitter.com/MuftiEbrahim?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Twitter
                                </a>
                            </li>
                            <li><Link to={Routes.audioList}>Social</Link></li>
                            <li><Link to={Routes.newsletters(1)}>Newsletter</Link></li>
                        </ul>
                    </Col>

                    <Col sm={3} className="custom-col-6">
                        <h2>Our Network</h2>
                        <ul className="bottom-links">
                            <li>
                                <a
                                    href="http://idealwoman.org/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Idealwoman.org
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://daruliftaa.net/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Daruliftaa
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://darulmahmood.net"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Darul Mahmood
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://one-humanity.net/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    One Humanity
                                </a>
                            </li>
                        </ul>
                    </Col>

                    <Col sm={3} className="custom-col-6">
                        <div className="copy-rights">Copyright © -2021 AskImam.org</div>
                        <br />
                        <form
                            action="https://www.paypal.com/cgi-bin/webscr"
                            method="post"
                            target="_blank"
                        >
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input
                                type="hidden"
                                name="hosted_button_id"
                                value="WBX3GRTLDMVD8"
                            />
                            <input
                                type="image"
                                src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                                border="0"
                                name="submit"
                                title="PayPal - The safer, easier way to pay online!"
                                alt="Donate with PayPal button"
                                className="donate-link"
                            />
                        </form>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="copy-rights text-center" style={{ marginBottom: '15px' }}>
                            All content on this website is the property of Askimam.org and
                            protected under international copyright laws.
                            Use of any such material without permission is prohibited.
                            Askimam.org is independently owned and operated by Askimam.org.
                            The views expressed by Mufti
                            Ebrahim Desai are based on academic understanding and research.
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
);

export default FooterLinks;
