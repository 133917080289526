import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import SingleColumnLayout from './SingleColumnLayout';

const SingleColumnLayoutRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={matchProps => <SingleColumnLayout><Component {...matchProps} /></SingleColumnLayout>}
    />
);

SingleColumnLayoutRoute.propTypes = {
    component: PropTypes.any.isRequired,
};

export default SingleColumnLayoutRoute;
