import React from 'react';
import { Link } from 'react-router-dom';

import HEADER from '../../../assets/images/header.webp';
import '../../../assets/styles/Header.css';

const SubscriptionHeader = () => (
    <header id="header">
        <div id="logo-holder">
            <div className="container-flueid">
                <Link to="/">
                    <img
                        className="header-banner"
                        src={HEADER}
                        alt="AskImam"
                    />
                </Link>
            </div>
        </div>

    </header>
);

export default SubscriptionHeader;
