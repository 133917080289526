const Routes = {
    home: '/',
    fatwasByPage: pageNo => (pageNo ? `/page:${pageNo}` : '/page::page'),
    fatwasByCategory: '/public/cat_fatwa_details',
    register: '/register',
    login: '/users/login',
    about: '/about',
    scholars: '/scholars',
    indices: '/indices',
    darulIftaa: '/affiliatedsites',
    nasiha: '/nasiha',
    contact: '/contact',
    disclaimer: '/disclaimer',
    askQuestion: '/public/ask_question',
    questionDetail: fatwaId => (fatwaId ? `/public/question_detail/${fatwaId}` : '/public/question_detail/:fatwaId'),
    hadithDetail: hadithId => (hadithId ? `/public/hadith_detail/${hadithId}` : '/public/hadith_detail/:hadithId'),
    announcementDetail: announcementId => (announcementId ? `/public/announcement/${announcementId}` : '/public/announcement/:announcementId'),
    newsletterDetail: newsletterId => (newsletterId ? `/public/newsletter/${newsletterId}` : '/public/newsletter/:newsletterId'),
    advanceSearch: '/public/adv-search',
    forgotPassword: '/users/forgot',
    resetPassword: '/users/reset_password',
    subscribe: uuid => (uuid ? `/subscribe/thankyou/${uuid}` : '/subscribe/thankyou/:uuid'),
    subscribeSettings: uuid => (uuid ? `/subscribe/preferences/${uuid}` : '/subscribe/preferences/:uuid'),
    hadiths: '/public/hadiths',
    audioList: '/public/audio-fatawa',
    hadithsByPage: pageNo => (pageNo ? `/public/hadiths/page:${pageNo}` : '/public/hadiths/page::pageNo'),
    researches: '/public/researches',
    researchesByPage: pageNo => (pageNo ? `/public/researches/page:${pageNo}` : '/public/researches/page::pageNo'),
    basicSearch: '/public/search',
    newsletters: pageNo => (pageNo ? `/newsletters/page:${pageNo}` : '/newsletters/page::pageNo'),
};

export default Routes;
