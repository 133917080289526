import { REGISTER_SUCCESS } from '../actions/actionTypes';

const initialState = {
    registered: false,
};

export default function showRegisterationMessage(state = initialState, action) {
    if (action.type === REGISTER_SUCCESS) {
        return {
            ...state,
            registered: action.data,
        };
    }
    return state;
}
