import {
    LIST_HADITH_SUCCESS,
    LIST_HADITHS_SUCCESS,
    LIST_HADTIH_FAILURE,
    LIST_HADTIHS_FAILURE,
    SET_HADITHS_IS_LOADING,
    SET_HADITH_IS_LOADING,
} from '../actions/actionTypes';

const hadithsInitialState = {
    isLoading: false,
    listHadithsSuccess: true,
    hadiths: [],
};

const hadithInitialState = {
    isLoading: false,
    listHadithSuccess: true,
    hadith: {},
};

export function listHadiths(state = hadithsInitialState, action) {
    switch (action.type) {
        case LIST_HADITHS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hadiths: action.data,
            };

        case LIST_HADTIHS_FAILURE:
            return {
                ...state,
                isLoading: false,
                listHadithsSuccess: false,
            };

        case SET_HADITHS_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        default:
            return state;
    }
}


export function listHadith(state = hadithInitialState, action) {
    switch (action.type) {
        case LIST_HADITH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hadith: action.data,
            };

        case LIST_HADTIH_FAILURE:
            return {
                ...state,
                isLoading: false,
                listHadithsSuccess: false,
            };
        case SET_HADITH_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        default:
            return state;
    }
}
