import {
    LIST_FATWAS_FAILURE,
    LIST_FATWAS_SUCCESS, SET_FATWA_IS_LOADING,
    SET_FATWAS_IS_LOADING,
} from '../actions/actionTypes';

const fatwasInitialState = {
    isLoading: false,
    listFatwasSuccess: true,
    fatwas: [],
    keyword: '',
    offset: 0,
    advanceSearch: {
        categoryId: '',
        fatwaId: '',
        question: '',
        title: '',
        answer: '',
        offset: 0,
    },
};

const fatwaInitialState = {
    isLoading: false,
    listFatwaSuccess: true,
    fatwa: {},
};

export function listFatwas(state = fatwasInitialState, action) {
    switch (action.type) {
        case LIST_FATWAS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                fatwas: action.data,
            };

        case LIST_FATWAS_FAILURE:
            return {
                ...state,
                isLoading: false,
                listFatwasSuccess: false,
            };
        case SET_FATWAS_IS_LOADING: {
            const {
                keyword,
                offset,
                advanceSearch,
            } = action?.payload || {};

            return {
                ...state,
                isLoading: true,
                fatwas: [],
                keyword,
                offset,
                advanceSearch,
            };
        }

        default:
            return state;
    }
}

export function listFatwa(state = fatwaInitialState, action) {
    switch (action.type) {
        case LIST_FATWAS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                fatwa: action.data,
            };

        case LIST_FATWAS_FAILURE:
            return {
                ...state,
                isLoading: false,
                listFatwaSuccess: false,
            };

        case SET_FATWA_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        default:
            return state;
    }
}
