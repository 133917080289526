import {
    LIST_FATWAS_FAILURE,
    LIST_FATWAS_SUCCESS,
    SET_FATWA_IS_LOADING,
    SET_FATWAS_IS_LOADING,
} from './actionTypes';
import {
    listCriteriaFilteredFatwasAPI,
    listFatwaAPI,
    listFatwasAPI,
    listKeywordFilteredFatwasAPI,
    listRandomFatwaAPI,
} from '../api/fatwaAPI';
import { setSearchBarKeyword } from './SearchBarActions';


function listFatwasSuccess(data) {
    return {
        data,
        type: LIST_FATWAS_SUCCESS,
    };
}

function listFatwasFailure(response) {
    return {
        response,
        type: LIST_FATWAS_FAILURE,
    };
}

export function listFatwas(categoryId, offset) {
    return (dispatch) => {
        dispatch({ type: SET_FATWAS_IS_LOADING });

        listFatwasAPI(categoryId, offset)
            .then(response => dispatch(listFatwasSuccess(response.data)))
            .catch(response => dispatch(listFatwasFailure(response)));
    };
}

export function listFatwa(fatwaId) {
    return dispatch => listFatwaAPI(fatwaId)
        .then(response => dispatch(listFatwasSuccess(response.data)))
        .catch(response => dispatch(listFatwasFailure(response)));
}

export function listRandomFatwa() {
    return dispatch => listRandomFatwaAPI()
        .then(response => dispatch(listFatwasSuccess(response.data)))
        .catch(response => dispatch(listFatwasFailure(response)));
}

export function listCriteriaFilteredFatwas(categoryId, fatwaId, question, title, answer, offset) {
    const payload = {
        advanceSearch: {
            categoryId, fatwaId, question, title, answer, offset,
        },
    };
    return (dispatch) => {
        dispatch({ type: SET_FATWAS_IS_LOADING, payload });

        listCriteriaFilteredFatwasAPI(categoryId, fatwaId, question, title, answer, offset)
            .then(response => dispatch(listFatwasSuccess(response.data)))
            .catch(response => dispatch(listFatwasFailure(response)));
    };
}

export function listKeywordFilteredFatwas(keyword, offset) {
    return (dispatch) => {
        dispatch({ type: SET_FATWAS_IS_LOADING, payload: { keyword, offset } });
        dispatch(setSearchBarKeyword(keyword));

        listKeywordFilteredFatwasAPI(keyword, offset)
            .then(response => dispatch(listFatwasSuccess(response.data)))
            .catch(response => dispatch(listFatwasFailure(response)));
    };
}

export function setFatwasIsLoadingTrue() {
    return dispatch => dispatch({ type: SET_FATWAS_IS_LOADING });
}

export function setFatwaIsLoadingTrue() {
    return dispatch => dispatch({ type: SET_FATWA_IS_LOADING });
}
