import { LIST_CATEGORIES_FAILURE, LIST_CATEGORIES_SUCCESS } from '../actions/actionTypes';

const initialState = {
    listCategoriesSuccess: true,
    categories: [],
};

export default function listCategories(state = initialState, action) {
    switch (action.type) {
        case LIST_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.data,
            };

        case LIST_CATEGORIES_FAILURE:
            return {
                ...state,
                listCategoriesSuccess: false,
            };

        default:
            return state;
    }
}
