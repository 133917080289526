import {
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD,
} from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    error: null,
    reset: false,
};

export default function resetPassword(state = initialState, action) {
    switch (action.type) {
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.response.data,
                isLoading: false,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                error: null,
                reset: action.data,
                isLoading: false,
            };
        case RESET_PASSWORD:
            return {
                ...state,
                isLoading: true,
            };
        default:
            return state;
    }
}
