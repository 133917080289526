import {
    LIST_RESEARCH_FAILURE,
    LIST_RESEARCH_SUCCESS,
    SET_RESEARCHES_IS_LOADING,
} from '../actions/actionTypes';

const researchesInitialState = {
    isLoading: false,
    listResearchesSuccess: true,
    researches: [],
};

export default function listResearches(state = researchesInitialState, action) {
    switch (action.type) {
        case LIST_RESEARCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                researches: action.data,
            };

        case LIST_RESEARCH_FAILURE:
            return {
                ...state,
                isLoading: false,
                listResearchesSuccess: false,
            };

        case SET_RESEARCHES_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        default:
            return state;
    }
}
