import axios from 'axios';

function get(url, customHeaders = {}) {
    const headers = {
        ...customHeaders,
        accept: 'application/json',
    };

    return axios.get(
        url,
        {
            headers,
        },
    );
}

function post(url, data, customHeaders = {}) {
    const headers = {
        'Content-Type': customHeaders.content_type || 'application/json',
        accept: customHeaders.accept || 'application/json',
    };

    if (customHeaders.authorization) {
        headers.authorization = customHeaders.authorization;
    }

    return axios.post(
        url,
        data,
        {
            headers,
        },
    );
}

function put(url, data, customHeaders = {}) {
    const headers = {
        'Content-Type': customHeaders.content_type || 'application/json',
        accept: customHeaders.accept || 'application/json',
    };

    if (customHeaders.authorization) {
        headers.authorization = customHeaders.authorization;
    }

    return axios.put(
        url,
        data,
        {
            headers,
        },
    );
}

export default { get, post, put };
