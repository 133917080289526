import { FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_IS_LOADING } from '../actions/actionTypes';

const initialState = {
    ForgotPasswordSuccess: null,
    email: null,
    isLoading: false,
};

export default function forgotPassword(state = initialState, action) {
    switch (action.type) {
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...action.data,
                ForgotPasswordSuccess: true,
                isLoading: false,
            };

        case FORGOT_PASSWORD_FAILURE:
            return {
                ...action.data,
                ForgotPasswordSuccess: false,
                error: action.response.data,
                isLoading: false,
            };

        case FORGOT_PASSWORD_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        default:
            return state;
    }
}
