import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { TinyButton as ScrollUpButton } from 'react-scroll-up-button';
import loadable from '@loadable/component';

import './App.css';
import { DefaultLoader, SingleColumnLayoutLoader } from './components/common/Loader/index';
import RegisterLayoutRoute from './components/Layouts/RegisterLayoutRoute';
import DefaultLayoutRoute from './components/Layouts/DefaultLayoutRoute';
import SingleColumnLayoutRoute from './components/Layouts/SingleColumnLayoutRoute';
import SubscriptionLayoutRoute from './components/Layouts/SubscriptionLayoutRoute';
import Routes from './constants/routes';
import { googleAnalyticsActions } from './utils/googleAnalytics';

import Fatwa from './components/Fatwa';
import FatwasList from './components/FatwasList';

const PrivateRoute = loadable(() => import(/* webpackChunkName: "routes" */ './utils/PrivateRoute'));
const Register = loadable(() => import(/* webpackChunkName: "register" */ './components/Register'), { fallback: <SingleColumnLayoutLoader /> });
const Login = loadable(() => import(/* webpackChunkName: "login" */ './components/Login'), { fallback: <SingleColumnLayoutLoader /> });
const ForgotPassword = loadable(() => import(/* webpackChunkName: "forgot-password" */ './components/ForgotPassword'));
const ResetPassword = loadable(() => import(/* webpackChunkName: "reset-password" */ './components/PasswordReset'));
const NewsletterList = loadable(() => import(/* webpackChunkName: "newsletter-list" */ './components/NewsletterList'));
const Home = loadable(() => import(/* webpackChunkName: "home" */ './components/Home'));
const Hadith = loadable(() => import(/* webpackChunkName: "hidith" */ './components/Hadith'));
const AudioList = loadable(() => import(/* webpackChunkName: "audioList" */ './components/AudioList'));
const Nasiha = loadable(() => import(/* webpackChunkName: "nasiha" */ './components/Nasiha'));
const Announcement = loadable(() => import(/* webpackChunkName: "announcement" */ './components/Announcement'));
const Newsletter = loadable(() => import(/* webpackChunkName: "newsletter" */ './components/Newsletter'));
const AskQuestion = loadable(() => import(/* webpackChunkName: "ask-question" */ './components/AskQuestion'));
const AdvanceSearch = loadable(() => import(/* webpackChunkName: "adv-advanceSearch" */ './components/AdvanceSearch'));
const Hadiths = loadable(() => import(/* webpackChunkName: "hadiths" */ './components/HadithList'), { fallback: <DefaultLoader /> });
const Researches = loadable(() => import(/* webpackChunkName: "researches" */ './components/Researches'), { fallback: <DefaultLoader /> });

const SubscriptionPopup = loadable(() => import(/* webpackChunkName: "subscription" */ './components/common/subscriptionPopup'));
const SubscribeThanks = loadable(() => import(/* webpackChunkName: "subscription-thankyou" */ './components/Subscription/SubscriptionThanks'));
const SubscribePreferences = loadable(() => import(/* webpackChunkName: "unsubscribe" */ './components/Subscription/Unsubscribe'));

const AffiliatedSites = loadable(() => import(/* webpackChunkName: "darul-iftaa" */ './components/DarulIftaa/AffiliatedSites'), { fallback: <DefaultLoader /> });
const Indices = loadable(() => import(/* webpackChunkName: "indices" */ './components/IslamicIndices'), { fallback: <DefaultLoader /> });
const Scholars = loadable(() => import(/* webpackChunkName: "scholars" */ './components/Scholars'), { fallback: <DefaultLoader /> });
const About = loadable(() => import(/* webpackChunkName: "about" */ './components/About'), { fallback: <DefaultLoader /> });
const Disclaimer = loadable(() => import(/* webpackChunkName: "disclaimer" */ './components/Disclaimer'), { fallback: <DefaultLoader /> });
const Contact = loadable(() => import(/* webpackChunkName: "contact-us" */ './components/Contact'), { fallback: <DefaultLoader /> });

class App extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
        };
    }

    componentDidMount() {
        // hook copy event so the current URL is appended to selected text
        document.addEventListener('copy', (e) => {
            e.preventDefault();
            let copiedText = document.getSelection().toString();
            copiedText += `\n\nRead more at ${window.location.href}`;
            e.clipboardData.setData('text/plain', copiedText);
        });
        googleAnalyticsActions.initGoogleAnalytics();
    }

    handlePopupClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const { open } = this.state;
        return (
            <BrowserRouter>
                <React.Fragment>
                    <ScrollUpButton
                        EasingType="easeOutCubic"
                        AnimationDuration={500}
                        ContainerClassName="scrollup"
                        style={{ width: 30, height: 30 }}
                    />
                    <Switch>
                        <DefaultLayoutRoute exact path={Routes.home} component={Home} />
                        <DefaultLayoutRoute exact path={Routes.fatwasByPage()} component={FatwasList} />

                        <RegisterLayoutRoute exact path={Routes.register} component={Register} />
                        <RegisterLayoutRoute exact path="/users/add" component={Register} />

                        <SingleColumnLayoutRoute exact path={Routes.login} component={Login} />
                        <SingleColumnLayoutRoute exact path={Routes.contact} component={Contact} />

                        <DefaultLayoutRoute
                            path={`${Routes.fatwasByCategory}/:categoryId/page::page`}
                            component={FatwasList}
                        />
                        <DefaultLayoutRoute
                            path={`${Routes.fatwasByCategory}/:categoryId`}
                            component={FatwasList}
                        />
                        <DefaultLayoutRoute path={Routes.questionDetail()} component={Fatwa} />
                        <DefaultLayoutRoute path={Routes.hadithDetail()} component={Hadith} />
                        <DefaultLayoutRoute path={Routes.newsletters()} component={NewsletterList} />

                        <DefaultLayoutRoute exact path={Routes.about} component={About} />
                        <DefaultLayoutRoute exact path={Routes.scholars} component={Scholars} />
                        <DefaultLayoutRoute exact path={Routes.indices} component={Indices} />
                        <DefaultLayoutRoute exact path={Routes.darulIftaa} component={AffiliatedSites} />
                        <DefaultLayoutRoute exact path={Routes.nasiha} component={Nasiha} />
                        <DefaultLayoutRoute exact path={Routes.disclaimer} component={Disclaimer} />
                        <DefaultLayoutRoute
                            exact
                            path={Routes.announcementDetail()}
                            component={Announcement}
                        />
                        <DefaultLayoutRoute
                            exact
                            path={Routes.newsletterDetail()}
                            component={Newsletter}
                        />
                        <PrivateRoute
                            exact
                            path={Routes.askQuestion}
                            {...this.props}
                            component={AskQuestion}
                        />
                        <DefaultLayoutRoute path={Routes.advanceSearch} component={AdvanceSearch} />
                        <DefaultLayoutRoute
                            exact
                            path={Routes.basicSearch}
                            component={AdvanceSearch}
                        />
                        <DefaultLayoutRoute path={Routes.audioList} component={AudioList} />
                        <DefaultLayoutRoute path={Routes.hadiths} component={Hadiths} />
                        <DefaultLayoutRoute path={Routes.hadithsByPage()} component={Hadiths} />
                        <DefaultLayoutRoute path={Routes.researches} component={Researches} />
                        <DefaultLayoutRoute path={Routes.researchesByPage()} component={Researches} />
                        <DefaultLayoutRoute path={Routes.forgotPassword} component={ForgotPassword} />
                        <DefaultLayoutRoute path={Routes.resetPassword} component={ResetPassword} />

                        <SubscriptionLayoutRoute path={Routes.subscribe()} component={SubscribeThanks} />
                        <SubscriptionLayoutRoute path={Routes.subscribeSettings()} component={SubscribePreferences} />
                    </Switch>

                    <SubscriptionPopup open={open} handleClose={this.handlePopupClose} />
                </React.Fragment>
            </BrowserRouter>
        );
    }
}

export default App;
