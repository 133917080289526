import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import { isEmpty } from '../../../utils/lodashUtils';
import { logout } from '../../../actions/loginActions';
import { getUser, writeIslamicDate } from '../../../utils/utils';
import { setSearchBarKeyword } from '../../../actions/SearchBarActions';
import Routes from '../../../constants/routes';
import { isTokenExpired } from '../../../utils/validators';
import { YOUTUBE_CHANNEL_URL, WHATS_APP_URL } from '../../../constants/globals';

class TopNavigationBar extends Component {
    clearSearchBarKeyword = () => {
        this.props.setSearchBarKeyword('');
    };

    render() {
        const { date } = new Date();
        const tokenExpired = isTokenExpired(getUser());
        const isLoggedOut = isEmpty(this.props.userReducer.user) || tokenExpired;

        return (
            <div>
                <div className="top-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-sm-5 col-xs-4 social-area">
                                <ul className="social-nav">
                                    <li className="icon">
                                        <a
                                            target="_blank"
                                            href="https://twitter.com/@Darul_iftaa"
                                            rel="noopener noreferrer"
                                        >
                                            <span className="icon-link"><i className="fa fa-twitter" /></span>
                                        </a>
                                    </li>
                                    <li className="icon">
                                        <a
                                            target="_blank"
                                            href={YOUTUBE_CHANNEL_URL}
                                            rel="noopener noreferrer"
                                        >
                                            <span className="icon-link"><i className="fa fa-youtube-play" /></span>
                                        </a>
                                    </li>
                                    <li className="icon">
                                        <a href={WHATS_APP_URL} target="_blank" rel="noopener noreferrer">
                                            <span className="icon-link"><i className="fa fa-whatsapp" /></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-8 date-area">
                                <span className="date">
                                    {
                                        new Intl.DateTimeFormat('en-GB', {
                                            weekday: 'long',
                                        }).format(date)
                                    }
                                    {writeIslamicDate(date)}
                                    {' - '}
                                    {
                                        new Intl.DateTimeFormat('en-GB', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        }).format(date)
                                    }
                                </span>
                            </div>
                            <div className="col-lg-6 col-xs-12">
                                <ul className="top-nav">
                                    <li>
                                        <Link
                                            to={Routes.advanceSearch}
                                            onClick={this.clearSearchBarKeyword}
                                        >
                                            Advanced Search
                                        </Link>
                                    </li>
                                    <li><Link to={Routes.questionDetail('random')}>Random</Link></li>
                                    <li><Link to={Routes.askQuestion}>Submit Question</Link></li>
                                    {
                                        !tokenExpired
                                        && (
                                            <li>
                                                <Link to="" onClick={() => this.props.logout(this.props.history)}>
                                                    logout
                                                </Link>
                                            </li>
                                        )
                                    }
                                    {
                                        isLoggedOut
                                        && (
                                            <li>
                                                <Link to={Routes.login}>Sign in</Link>
                                            </li>
                                        )
                                    }
                                    {
                                        isLoggedOut
                                        && (
                                            <li>
                                                <Link to={Routes.register}>Register</Link>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TopNavigationBar.propTypes = {
    userReducer: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    setSearchBarKeyword: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        userReducer: state.loginReducer,
    };
}

export default withRouter(connect(mapStateToProps, { logout, setSearchBarKeyword })(TopNavigationBar));
