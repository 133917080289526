import React from 'react';
import PropTypes from 'prop-types';

import { Col } from '../../utils/bootstrapUtils';
import '../../assets/styles/Common.css';
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';

const SingleColumnLayout = ({ children }) => (
    <div>
        <Header />
        <div className="container-fluid full-page-container">
            <Col md={12} className="custom-column">
                {children}
            </Col>
        </div>
        <Footer />
    </div>

);

SingleColumnLayout.propTypes = {
    children: PropTypes.any.isRequired,
};

export default SingleColumnLayout;
