import {
    LIST_NEWSLETTER_SUCCESS, LIST_NEWSLETTER_FAILURE,
    SET_NEWSLETTERS_IS_LOADING, NEWSLETTER_DETAIL_SUCCESS,
    NEWSLETTER_DETAIL_FAILURE,
} from '../actions/actionTypes';


const initialState = {
    isLoading: false,
    count: 0,
    newsletters: [],
    newsletterDetail: {},
    error: '',
};

export function newsletterReducer(state = initialState, action) {
    switch (action.type) {
        case LIST_NEWSLETTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newsletters: action.data.results,
                count: action.data.count,
            };
        case LIST_NEWSLETTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                newsletters: [],
                error: action.data,
            };
        case SET_NEWSLETTERS_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case NEWSLETTER_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newsletterDetail: action.data,
            };
        case NEWSLETTER_DETAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                newsletterDetail: {},
                error: action.data,
            };
        default:
            return state;
    }
}
