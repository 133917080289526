import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

import '../../assets/styles/ErrorBoundary.css';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isChunkFailedError: false,
        };
    }

    componentDidCatch(error) {
        Sentry.captureException(error);
    }

    static getDerivedStateFromError(error) {
        console.log('error name', error.name);
        if (error.name.includes('ChunkLoadError')) {
            return { hasError: true, isChunkFailedError: true };
        }

        return { hasError: true, isChunkFailedError: false };
    }

    render() {
        const { hasError, isChunkFailedError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <div id="errorPageContainer">
                    <div className="errorPage">
                        <h2>Oops! Something went wrong.</h2>
                        {isChunkFailedError ? (
                            <p>
                                <span>Please </span>
                                <button
                                    type="button"
                                    className="reloadLink"
                                    onClick={() => window.location.reload()}
                                >
                                    reload page
                                </button>
                                <span> to try again. </span>
                                <span>Kindly note that we keep track of these errors.</span>
                            </p>
                        ) : (
                            <p>Please try again. Kindly note that we keep track of these errors.</p>
                        )}
                    </div>
                </div>
            );
        }
        return children;
    }
}

export default ErrorBoundary;
