import { SLIDER_IMAGE_SET } from '../actions/actionTypes';

const SliderImagesInitialState = {
    sliderImages: [],
};


export default function listSliderImages(state = SliderImagesInitialState, action) {
    if (action.type === SLIDER_IMAGE_SET) {
        const sliderImages = [];
        action.images.forEach((value) => {
            const sliderImage = {
                id: value.id,
                image: value.image,
            };
            sliderImages.push(sliderImage);
        });
        return {
            ...state,
            sliderImages,
        };
    }
    return state;
}
