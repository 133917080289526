import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../../assets/styles/SearchBar.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { listKeywordFilteredFatwas } from '../../../actions/FatwaActions';
import { setSearchBarKeyword } from '../../../actions/SearchBarActions';
import Routes from '../../../constants/routes';
import { computeOffsetFromPageNo, extractParamsFromUrl } from '../../../utils/utils';
import { FATWAS_PER_PAGE } from '../../../constants/globals';

const ENTER_KEY_CODE = 13;

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            keyword: this.props.searchBarReducer.keyword,
        };
    }

    componentDidMount() {
        if (this.isSearchPageOpened()) {
            const params = extractParamsFromUrl();
            const { keyword: searchKeyword, page = 1 } = params;

            if (this.state.keyword !== searchKeyword) {
                const offset = computeOffsetFromPageNo(page, FATWAS_PER_PAGE);
                this.setState({ keyword: searchKeyword });
                this.props.listKeywordFilteredFatwas(searchKeyword, offset);
                this.props.history.push({
                    pathname: Routes.basicSearch,
                    search: `?keyword=${searchKeyword}&page=${page}`,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const prevKeyword = prevProps.searchBarReducer.keyword;
        const newKeyword = this.props.searchBarReducer.keyword;

        this.updateKeywordIfNeeded(prevKeyword, newKeyword);
    }

    isSearchPageOpened = () => this.props.location.pathname.includes(Routes.basicSearch);

    updateKeywordIfNeeded = (prevKeyword, newKeyword) => {
        if (prevKeyword !== newKeyword) {
            this.setState({ keyword: newKeyword });
        }
    };

    clearKeyword = () => this.props.setSearchBarKeyword('');

    handleChange = (data) => {
        data.preventDefault();
        const { name } = data.target;
        this.setState({ [name]: data.target.value });
        if (!data.target.value) {
            this.clearKeyword();
        }
    };

    handleEnter = (event) => {
        if (event.keyCode === ENTER_KEY_CODE) {
            this.handleSearchKeyword();
        }
    };

    handleSearchKeyword = () => {
        const { keyword } = this.state;
        if (keyword.length > 0) {
            this.props.listKeywordFilteredFatwas(keyword);
            this.props.history.push({
                pathname: Routes.basicSearch,
                search: `?keyword=${keyword}&page=1`,
            });
        }
    };

    render() {
        const { keyword } = this.state;
        return (
            <div id="SearchFormKeyword">
                <input
                    type="text"
                    id="search-textfield"
                    placeholder="Search..."
                    name="keyword"
                    onChange={this.handleChange}
                    onKeyDown={this.handleEnter}
                    value={keyword}
                />
                <button
                    id="search_button"
                    onClick={this.handleSearchKeyword}
                    type="submit"
                >
                    <i
                        className="fa fa-search"
                    />
                </button>
            </div>
        );
    }
}

SearchBar.propTypes = {
    location: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    searchBarReducer: PropTypes.object.isRequired,
    listKeywordFilteredFatwas: PropTypes.func.isRequired,
    setSearchBarKeyword: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    searchBarReducer: state.searchBarReducer,
});

export default withRouter(connect(mapStateToProps, { listKeywordFilteredFatwas, setSearchBarKeyword })(SearchBar));
