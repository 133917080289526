import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';

import 'jquery/src/jquery';
import 'bootstrap/dist/js/bootstrap.min';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import ErrorBoundary from './components/ErrorBoundary';

import App from './App';
import configureStore from './store/configureStore';

if (process.env.NODE_ENV === 'production') {
    const environment = process.env.NODE_ENV === 'development' ? 'stage' : 'prod';
    const sentryConfig = {
        dsn: process.env.REACT_APP_SENTRY_DNS,
        environment,
        release: `askimam-${environment}-${process.env.REACT_APP_PRODUCTION_RELEASE}`,
    };
    Sentry.init(sentryConfig);
}

const store = configureStore();

render(
    <Provider store={store}>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </Provider>,
    document.getElementById('root'),
);
