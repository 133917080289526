import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import SubscriptionLayout from './SubscriptionLayout';

const SubscriptionLayoutRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={matchProps => <SubscriptionLayout><Component {...matchProps} /></SubscriptionLayout>}
    />
);

SubscriptionLayoutRoute.propTypes = {
    component: PropTypes.any.isRequired,
};

export default SubscriptionLayoutRoute;
