import { combineReducers } from 'redux';
import authenticate from './loginReducer';
import listCategories from './NavigationReducer';
import listConstants from './ConstantsReducer';
import listDaruliftaa from './DaruliftaaReducer';
import { listAnnouncement, listAnnouncements, listFeaturedAnnouncements } from './AnnouncementsReducer';
import { listFatwa, listFatwas } from './FatwaReducer';
import postQuestion from './postQuestionReducer';
import postMessage from './postMessageReducer';
import { listHadith, listHadiths } from './hadithReducer';
import listResearches from './researchReducer';
import subscribe from './SubscriptionReducer';
import listSliderImages from './SliderImageReducer';
import forgotPassword from './ForgotPasswordReducer';
import resetPassword from './ResetPasswordReducer';
import showRegisterationMessage from './RegistrationReducer';
import listSideWidgetImages from './sideWidgetImageReducer';
import searchBarReducer from './SearchBarReducer';
import { newsletterReducer } from './NewsletterReducer';
import { listAudiosReducer } from './AudiosReducer';

export default combineReducers({
    loginReducer: authenticate,
    listCategoriesReducer: listCategories,
    listFatwasReducer: listFatwas,
    listFatwaReducer: listFatwa,
    listConstantsReducer: listConstants,
    listAnnouncementsReducer: listAnnouncements,
    listFeaturedAnnouncementsReducer: listFeaturedAnnouncements,
    listAnnouncementReducer: listAnnouncement,
    postQuestionReducer: postQuestion,
    postMessageReducer: postMessage,
    listhadithReducer: listHadith,
    listhadithsReducer: listHadiths,
    sideWidgetImagesReducer: listSideWidgetImages,
    listResearchesReducer: listResearches,
    subscriptionReducer: subscribe,
    sliderImageReducer: listSliderImages,
    forgotPasswordReducer: forgotPassword,
    resetPasswordReducer: resetPassword,
    registerMessageReducer: showRegisterationMessage,
    newsletterReducer,
    searchBarReducer,
    listAudiosReducer,
    listDaruliftaaReducer: listDaruliftaa,
});
