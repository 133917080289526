export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_SUCCESS_RESET = 'LOGIN_SUCCESS_RESET';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'USERS_LOGOUT';

export const LIST_CATEGORIES_SUCCESS = 'LIST_CATEGORIES_SUCCESS';
export const LIST_CATEGORIES_FAILURE = 'LIST_CATEGORIES_FAILURE';

export const LIST_DARULIFTAA_FAILURE = 'LIST_DARULIFTAA_FAILURE';
export const LIST_DARULIFTAA_SUCCESS = 'LIST_DARULIFTAA_SUCCESS';
export const LIST_DARULIFTAA = 'LIST_DARULIFTAA';

export const LIST_FATWAS_SUCCESS = 'LIST_FATWAS_SUCCESS';
export const LIST_FATWAS_FAILURE = 'LIST_FATWAS_FAILURE';
export const SET_FATWAS_IS_LOADING = 'SET_FATWAS_IS_LOADING';
export const SET_FATWA_IS_LOADING = 'SET_FATWA_IS_LOADING';

export const SET_SEARCH_BAR_KEYWORD = 'SET_SEARCH_BAR_KEYWORD';

export const LIST_CONSTANTS_SUCCESS = 'LIST_CONSTANTS_SUCCESS';
export const LIST_CONSTANTS_FAILURE = 'LIST_CONSTANTS_FAILURE';
export const SET_LIST_CONSTANTS_IS_LOADING = 'SET_LIST_CONSTANTS_IS_LOADING';

export const LIST_ANNOUNCEMENTS_SUCCESS = 'LIST_ANNOUNCEMENTS_SUCCESS';
export const LIST_ANNOUNCEMENTS_FAILURE = 'LIST_ANNOUNCEMENTS_FAILURE';

export const LIST_FEATURED_ANNOUNCEMENTS_SUCCESS = 'LIST_FEATURED_ANNOUNCEMENTS_SUCCESS';
export const LIST_FEATURED_ANNOUNCEMENTS_FAILURE = 'LIST_FEATURED_ANNOUNCEMENTS_FAILURE';

export const LIST_SIDE_WIDGETS_SUCCESS = 'LIST_SIDE_WIDGETS_SUCCESS';
export const LIST_SIDE_WIDGETS_FAILURE = 'LIST_SIDE_WIDGETS_FAILURE';
export const SET_SIDE_WIDGETS_IMAGES_IS_LOADING = 'SET_SIDE_WIDGETS_IMAGES_IS_LOADING';

export const LIST_ANNOUNCEMENT_SUCCESS = 'LIST_ANNOUNCEMENT_SUCCESS';
export const LIST_ANNOUNCEMENT_FAILURE = 'LIST_ANNOUNCEMENT_FAILURE';
export const SET_ANNOUNCEMENT_IS_LOADING = 'SET_ANNOUNCEMENT_IS_LOADING';

export const POST_QUESTION_SUCCESS = 'POST_QUESTION_SUCCESS';
export const POST_QUESTION_FAILURE = 'POST_QUESTION_FAILURE';

export const POST_MESSAGE_SUCCESS = 'POST_MESSAGE_SUCCESS';
export const POST_MESSAGE_FAILURE = 'POST_MESSAGE_FAILURE';
export const SET_POST_QUESTION_FALSE = 'SET_POST_QUESTION_FALSE';
export const SET_POST_QUESTION_IS_LOADING = 'SET_POST_QUESTION_IS_LOADING';

export const TOKEN_EXPIRED_TRUE = 'TOKEN_EXPIRED_TRUE';
export const TOKEN_EXPIRED_FALSE = 'TOKEN_EXPIRED_FALSE';

export const LIST_HADITH_SUCCESS = 'LIST_HADITH_SUCCESS';
export const LIST_HADTIH_FAILURE = 'LIST_HADITH_FAILURE';
export const LIST_HADITHS_SUCCESS = 'LIST_HADITHS_SUCCESS';
export const LIST_HADTIHS_FAILURE = 'LIST_HADITHS_FAILURE';
export const SET_HADITHS_IS_LOADING = 'SET_HADITHS_IS_LOADING';
export const SET_HADITH_IS_LOADING = 'SET_HADITH_IS_LOADING';

export const LIST_RESEARCH_SUCCESS = 'LIST_RESEARCH_SUCCESS';
export const LIST_RESEARCH_FAILURE = 'LIST_RESEARCH_FAILURE';
export const SET_RESEARCHES_IS_LOADING = 'SET_RESEARCHES_IS_LOADING';

export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE';
export const SUBSCRIPTION_IS_LOADING = 'SUBSCRIPTION_IS_LOADING';

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_IS_LOADING = 'FORGOT_PASSWORD_IS_LOADING';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const SLIDER_IMAGE_SET = 'SLIDER_IMAGE_SET';

export const LIST_NEWSLETTER_SUCCESS = 'LIST_NEWSLETTER_SUCCESS';
export const LIST_NEWSLETTER_FAILURE = 'LIST_NEWSLETTER_FAILURE';
export const SET_NEWSLETTERS_IS_LOADING = 'SET_NEWSLETTERS_IS_LOADING';

export const NEWSLETTER_DETAIL_SUCCESS = 'NEWSLETTER_DETAIL_SUCCESS';
export const NEWSLETTER_DETAIL_FAILURE = 'NEWSLETTER_DETAIL_FAILURE';

export const LIST_AUDIOS_SUCCESS = 'LIST_AUDIOS_SUCCESS';
export const LIST_AUDIOS_FAILURE = 'LIST_AUDIOS_FAILURE';
export const LIST_AUDIOS_IS_LOADING = 'LIST_AUDIOS_IS_LOADING';
