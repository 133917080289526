import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Row } from '../../../utils/bootstrapUtils';
import '../../../assets/styles/NewsTicker.css';
import Announcements from './Announcements';
import { listAnnouncement, listAnnouncements } from '../../../actions/AnnouncementActions';
import Routes from '../../../constants/routes';

class NewsTicker extends Component {
    state = {
        offset: 0,
        intervalId: null,
    };

    componentDidMount() {
        this.props.listAnnouncements();

        const intervalId = setInterval(() => this.updateOffset(), 4000);
        this.setState({ intervalId });
    }

    componentWillUnmount() {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
    }

    updateOffset() {
        const { announcements } = this.props;
        this.setState(prevState => ({ offset: (prevState.offset + 1) % announcements.length }));
    }

    render() {
        const { announcements, listAnnouncementsSuccess } = this.props;
        const announcementsList = announcements.map(
            announcement => (
                <Link
                    to={Routes.announcementDetail(announcement.id)}
                    onClick={() => {
                        this.props.listAnnouncement(announcement.id);
                    }}
                >
                    {announcement.title}
                </Link>
            ),
        );

        return (
            <div>
                <div id="news-ticker">
                    <div className="container">
                        <Row>
                            <div className="latest-updates">
                                <div className="news-title">
                                    Latest Updates
                                </div>
                                <div className="news-scroller">
                                    <div>
                                        {listAnnouncementsSuccess ? announcementsList[this.state.offset] : ''}
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>

                {
                    this.props.history.location.pathname === '/' ?
                        <Announcements {...this.props} />
                        : ''
                }
            </div>

        );
    }
}

NewsTicker.defaultProps = {
    announcements: null,
};

NewsTicker.propTypes = {
    history: PropTypes.object.isRequired,
    announcements: PropTypes.array,
    listAnnouncementsSuccess: PropTypes.bool.isRequired,
    listAnnouncements: PropTypes.func.isRequired,
    listAnnouncement: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    listAnnouncementsReducer: { listAnnouncementsSuccess, announcements },
}) => ({
    listAnnouncementsSuccess,
    announcements,
});

export default withRouter(connect(mapStateToProps, {
    listAnnouncements,
    listAnnouncement,
})(NewsTicker));
