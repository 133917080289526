import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import SingleColumnLayout from './SingleColumnLayout';
import { isTokenExpired } from '../../utils/validators';
import { getUser } from '../../utils/utils';

const RegisterLayoutRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(matchProps) => {
            const user = getUser();

            if (!isTokenExpired(user)) {
                return (<Redirect to="/" />);
            }
            return <SingleColumnLayout><Component {...matchProps} /></SingleColumnLayout>;
        }}
    />
);

RegisterLayoutRoute.propTypes = {
    component: PropTypes.any.isRequired,
};

export default RegisterLayoutRoute;
