import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { Col, Row } from '../../../utils/bootstrapUtils';
import SearchBar from './SearchBar';
import { listCategories } from '../../../actions/NavigationActions';
import Routes from '../../../constants/routes';


class MainNavigationBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCategories: false,
            showSubCategories: null,
        };
    }

    componentDidMount() {
        this.props.listCategories();
    }

    handleNavigation = (event) => {
        const target = event.target.dataset.target;
        this.props.history.push(target);
        document.querySelector('.navbar-toggle').click();
    };

    renderFatwaCategories() {
        const { categories } = this.props.categories;
        const prevState = this.state;

        return (
            <li
                className="main-nav-category"
                onMouseEnter={() => this.setState({
                    showCategories: !prevState.showCategories,
                })}
                onMouseLeave={() => this.setState({
                    showCategories: !prevState.showCategories,
                })}
            >

                <Link to="#"><span>Fatwas</span></Link>
                <ul className="sub-category">
                    {
                        categories.map(category => (
                            this.state.showCategories
                                && (
                                    <li
                                        key={category.id}
                                        className="sub-category-2"
                                        onMouseEnter={() => this.setState(
                                            { showSubCategories: category.id },
                                        )}
                                        onMouseLeave={() => this.setState(
                                            { showSubCategories: null },
                                        )}
                                    >
                                        <div>
                                            <span
                                                data-target={`/public/cat_fatwa_details/${category.old_id}`}
                                                onClick={category.children.length === 0 ? this.handleNavigation : undefined}
                                            >
                                                {category.name}
                                            </span>
                                        </div>
                                        <ul>
                                            {category.children.map(
                                                (subCategory) => {
                                                    if (this.state.showSubCategories === category.id) {
                                                        let url = `/public/cat_fatwa_details/${subCategory.old_id}`;
                                                        if (subCategory.slug === 'hadiths') {
                                                            url = '/public/cat_fatwa_details/13';
                                                        }
                                                        return (
                                                            <li key={subCategory.id}>
                                                                <div>
                                                                    <span
                                                                        data-target={url}
                                                                        onClick={this.handleNavigation}
                                                                    >
                                                                        {subCategory.name}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        );
                                                    }
                                                    return false;
                                                },
                                            )}
                                        </ul>
                                    </li>
                                )
                        ))
                    }

                </ul>
            </li>

        );
    }

    render() {
        return (
            <nav id="nav-holder">
                <div className="container">
                    <Row>
                        <Col md={12}>

                            {/* NavBar button */}

                            <button type="button" className="navbar-toggle collapsed" data-target="#navbar" data-toggle="collapse" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>

                            {/* Search */}

                            <div className="search-holder pull-right">
                                <SearchBar />
                            </div>

                            {/* Navigation */}
                            <div id="navbar" className="collapse navbar-collapse">
                                <ul id="navmenu-v" className="nav navbar-nav">
                                    <li>
                                        <div><span data-target="/" onClick={this.handleNavigation}>Home</span></div>
                                    </li>

                                    {this.renderFatwaCategories()}

                                    <li>
                                        <div><span data-target={Routes.about} onClick={this.handleNavigation}>About</span></div>
                                    </li>

                                    <li>
                                        <div>
                                            <span
                                                className="ask-question"
                                                data-target={Routes.askQuestion}
                                                onClick={this.handleNavigation}
                                            >Ask Question
                                            </span>
                                        </div>
                                    </li>

                                    <li>
                                        <div><span data-target={Routes.hadiths} onClick={this.handleNavigation}>Hadith</span></div>
                                    </li>

                                    <li>
                                        <div><span data-target={Routes.researches} onClick={this.handleNavigation}>Research</span></div>
                                    </li>

                                    <li>
                                        <div><span data-target={Routes.contact} onClick={this.handleNavigation}>Contact Us</span></div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </nav>
        );
    }
}

MainNavigationBar.defaultProps = {
    categories: null,
};

MainNavigationBar.propTypes = {
    history: PropTypes.object.isRequired,
    categories: PropTypes.object,
    listCategories: PropTypes.func.isRequired,
};

const mapStateToProps = ({ listCategoriesReducer }) => ({
    categories: listCategoriesReducer,
});

export default withRouter(connect(mapStateToProps, { listCategories })(MainNavigationBar));
